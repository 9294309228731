<div class="my-5">
    <div class="container">
        <div class="p-2">
            <div class="row">
                <div class="col-md-8 container">
                    <div class="my-1">
                        <h4 class="fw-bold">Contact Us</h4>
                        <p>Feel free to contact us if you need any assistance, any help or another question. Will try to
                            reach you out asap. </p>
                    </div>

                    <form [formGroup]="contactForm">
                        <div class="row g-3">
                            <div class="col-md-6">
                                <label for="firstName" class="form-label fw-bold">First Name</label>
                                <input type="text" class="form-control" id="firstName" [ngClass]="{
                                        'is-invalid': firstName.invalid && (firstName.touched || firstName.dirty),
                                        'is-valid':firstName.valid && (firstName.dirty || firstName.touched)
                                    }" formControlName="firstName" required>
                                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)"
                                    class="invalid-feedback">

                                    <div *ngIf="firstName.errors?.['required']">
                                        First Name is required.
                                    </div>


                                </div>

                                <div class="valid-feedback">
                                    Looks Good!
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label for="lastName" class="form-label fw-bold">Last Name</label>
                                <input type="text" class="form-control" [ngClass]="{
                                        'is-invalid': lastName.invalid && (lastName.touched || lastName.dirty),
                                        'is-valid':lastName.valid && (lastName.dirty || lastName.touched)
                                    }" id="lastName" formControlName="lastName" required>

                                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)"
                                    class="invalid-feedback">

                                    <div *ngIf="lastName.errors?.['required']">
                                        Last Name is required.
                                    </div>


                                </div>

                                <div class="valid-feedback">
                                    Looks Good!
                                </div>

                            </div>

                            <div class="col-md-6">
                                <label for="emailId" class="form-label fw-bold">Your Email</label>
                                <input type="text" class="form-control" id="email" formControlName="emailId" [ngClass]="{
                                    'is-invalid': emailId.invalid && (emailId.touched || emailId.dirty),
                                    'is-valid':emailId.valid && (emailId.dirty || emailId.touched)
                                }" required>
                                <div *ngIf="emailId.invalid && (emailId.dirty || emailId.touched)"
                                    class="invalid-feedback">

                                    <div *ngIf="emailId.errors?.['required']">
                                        Email Id is required.
                                    </div>


                                </div>


                                <div class="valid-feedback">
                                    Looks Good!
                                </div>

                            </div>

                            <div class="col-md-6">
                                <label for="subject" class="form-label fw-bold">Your Subject</label>
                                <input type="text" class="form-control" id="subject" formControlName="subject"
                                    [ngClass]="{
                                    'is-invalid': subject.invalid && (subject.touched || subject.dirty),
                                    'is-valid':subject.valid && (subject.dirty || subject.touched)
                                }" required>
                                <div *ngIf="subject.invalid && (subject.dirty || subject.touched)"
                                    class="invalid-feedback">

                                    <div *ngIf="subject.errors?.['required']">
                                        Subject is required.
                                    </div>


                                </div>

                                <div class="valid-feedback">
                                    Looks Good!
                                </div>
                            </div>

                            <div class="col-12">
                                <label for="message" class="form-label fw-bold">Your Message</label>
                                <textarea class="form-control" id="message" formControlName="message" [ngClass]="{
                                        'is-invalid': message.invalid && (message.touched || message.dirty),
                                        'is-valid':message.valid && (message.dirty || message.touched)
                                    }" rows="5" required></textarea>

                                <div class="mt-3 d-flex justify-content-end pr-4" *ngIf="message.errors?.['minlength']"
                                    style="font-size: 12px;">
                                    Message must be at least {{ message.errors?.['minlength'].actualLength }} characters
                                    long. we need another
                                    {{message.errors?.['minlength'].requiredLength -
                                    message.errors?.['minlength'].actualLength }} characters

                                </div>

                                <div *ngIf="message.invalid && (message.dirty || message.touched)"
                                    class="invalid-feedback">

                                    <div *ngIf="message.errors?.['required']">
                                        Message is required.
                                    </div>


                                </div>



                            </div>

                            <div class="col-12">
                                <div class="row">
                                    <div class="col-md-6">
                                        <button type="submit" class="btn btn-dark w-100 fw-bold"
                                            (click)="registerAndSaveQuery()"
                                            [disabled]="contactForm.invalid">Send</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        </div>
    </div>
</div>