

export class DatabaseHelper{
    
    public search:string;
    public currentPage:number;
    public itemPerPage:number;
    public sortBy:string;
    public sortOrder:string;
    
}