<nav class="navbar navbar-expand-lg navbar-light bg-light shadow">
  <div class="container">
    <a class="navbar-brand" href="#">
      BugFlu
      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-bug"
        viewBox="0 0 16 16">
        <path
          d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A5 5 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A5 5 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623M4 7v4a4 4 0 0 0 3.5 3.97V7zm4.5 0v7.97A4 4 0 0 0 12 11V7zM12 6a4 4 0 0 0-1.334-2.982A3.98 3.98 0 0 0 8 2a3.98 3.98 0 0 0-2.667 1.018A4 4 0 0 0 4 6z" />
      </svg> -->
    </a>
    <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-content">
      <div class="hamburger-toggle">
        <div class="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </button>
    <div class="collapse navbar-collapse row" id="navbar-content">
      <ul class="navbar-nav mr-auto mb-2 col-lg-5 mb-lg-0 mt-2 mb-2">
        <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
            data-bs-auto-close="outside">Multilevel</a>
          <ul class="dropdown-menu shadow">
            <li><a class="dropdown-item" href="#">Gallery</a></li>
            <li><a class="dropdown-item" href="blog.html">Blog</a></li>
            <li class="dropstart">
              <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown">Submenu Left</a>
              <ul class="dropdown-menu shadow">
                <li><a class="dropdown-item" href=""> Third level 1</a></li>
                <li><a class="dropdown-item" href=""> Third level 2</a></li>
                <li><a class="dropdown-item" href=""> Third level 3</a></li>
                <li><a class="dropdown-item" href=""> Third level 4</a></li>
                <li><a class="dropdown-item" href=""> Third level 5</a></li>
              </ul>
            </li>
            <li class="dropend">
              <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown"
                data-bs-auto-close="outside">Submenu Right</a>
              <ul class="dropdown-menu shadow">
                <li><a class="dropdown-item" href=""> Second level 1</a></li>
                <li><a class="dropdown-item" href=""> Second level 2</a></li>
                <li><a class="dropdown-item" href=""> Second level 3</a></li>
                <li class="dropend">
                  <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown"
                    data-bs-auto-close="outside">Let's go deeper!</a>
                  <ul class="dropdown-menu dropdown-submenu shadow">
                    <li><a class="dropdown-item" href=""> Third level 1</a></li>
                    <li><a class="dropdown-item" href=""> Third level 2</a></li>
                    <li><a class="dropdown-item" href=""> Third level 3</a></li>
                    <li><a class="dropdown-item" href=""> Third level 4</a></li>
                    <li class="dropend">
                      <a href="#" class="dropdown-item dropdown-toggle" data-bs-toggle="dropdown">Still don't have
                        enough? Go much deeper!</a>
                      <ul class="dropdown-menu dropdown-submenu shadow">
                        <li><a class="dropdown-item" href=""> Third level 1</a></li>
                        <li><a class="dropdown-item" href=""> Third level 2</a></li>
                        <li><a class="dropdown-item" href=""> Third level 3</a></li>
                        <li><a class="dropdown-item" href=""> Third level 4</a></li>
                        <li><a class="dropdown-item" href=""> Third level 5</a></li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li><a class="dropdown-item" href=""> Third level 5</a></li>
              </ul>
            </li>
            <li>
              <hr class="dropdown-divider">
            </li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" href="#" routerLink="/about">About</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#" routerLink="/contact">Contact Us</a>
        </li>

        <!-- Mega Menu-->

        <!-- <li class="nav-item dropdown dropdown-mega position-static">
          <a class="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown"
            data-bs-auto-close="outside">Megamenu</a>
          <div class="dropdown-menu shadow">
            <div class="mega-content px-4">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-12 col-sm-4 col-md-3 py-4">
                    <h5>Pages</h5>
                    <div class="list-group">
                      <a class="list-group-item" href="#">Accomodations</a>
                      <a class="list-group-item" href="#">Terms & Conditions</a>
                      <a class="list-group-item" href="#">Privacy</a>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 col-md-3 py-4">
                    <h5>Card</h5>
                    <div class="card">
                      <img src="https://via.placeholder.com/320x180" class="img-fluid" alt="image">
                      <div class="card-body">
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of
                          the card's content.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-sm-4 col-md-3 py-4">
                    <h5>Lot of Pages</h5>
                    <p>Lorem ipsum dolo sit achmet muhamed borlan de irtka.
                  </div>
                  <div class="col-12 col-sm-12 col-md-3 py-4">
                    <h5>Damn, so many</h5>
                    <div class="list-group">
                      <a class="list-group-item" href="#">Accomodations</a>
                      <a class="list-group-item" href="#">Terms & Conditions</a>
                      <a class="list-group-item" href="#">Privacy</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li> -->
        <!-- <li class="nav-item">
            <a class="nav-link disabled" href="#" tabindex="-1" aria-disabled="true">Disabled</a>
          </li> -->

      </ul>

      <div class="col-lg-4 mx-auto mt-2 mb-2">
        <app-search></app-search>
      </div>

      <div class=" ms-auto col-lg-3 mt-2 mb-2" [hidden]="isLoggedIn()">
        <button type="button" class="btn btn-square btn-primary me-2 " (click)="login($event)">Login/Register</button>
        <!-- <button type="button" class="btn btn-square btn-success">Sign Up</button> -->
      </div>

      <div class=" ms-auto col-lg-3 mt-2 mb-2" [hidden]="!isLoggedIn()">

        <ul class="navbar-nav">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../../assets/profile.png" style="position: relative;width: 25px;height:25px" alt="..."
                class="rounded-circle border border-dark">
            </a>
            <ul class="dropdown-menu dropdown-menu-light" aria-labelledby="navbarDarkDropdownMenuLink">
              <li><a class="dropdown-item" href="#" routerLink="/post/create">Write Something</a></li>
              <li><a class="dropdown-item" href="#">Profile</a></li>
              <li><a class="dropdown-item" href="#">Setting</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li><a class="dropdown-item bg-danger text-white" (click)="logout()">Log Out</a></li>
            </ul>
          </li>
        </ul>
      </div>


    </div>
  </div>
</nav>

<!-- <section class="my-5"> -->
<!-- <div class="container">
      <div class="p-4 border">
        <h1>Bootstrap <span class="text-primary">5.1.1</span> Navbar Multi Level and Mega Menu</h1>
        <p class="lead">This example shows a Navbar component of the Bootstrap Framework version 5 with the following features:</p>
        <ul>
            <li>No extra JavaSript required</li>
            <li>Multi Level Menu</li>
            <li>Responsive Mega Menu</li>
            <li>Unlimited Levels possible!</li>
            <li>Animated Hamburger Menu for Mobile Devices (with JavaScript)</li>
        </ul>
        <div class="alert alert-info"><strong>Updated:</strong> Now compatible with Bootstrap 5.1.1</div>
      <hr>
      <p>If you have any questions, feel free to contact me: <a href="https://simon-koehler.com/contact" target="_blank">https://simon-koehler.com/contact</a></p>
    </div>
    </div> -->
<!-- </section> -->